$primary-color: #5a67d8;
$bg-color: #f7fafc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;
$popup-bg: rgba(0, 0, 0, 0.5);

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $popup-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .popupInner {
    background-color: white;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;

    h2 {
      margin-bottom: 1rem;
      color: $primary-color;
    }

    input {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border: 1px solid $font-color;
      border-radius: $border-radius;
      font-size: 1rem;
      outline: none;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .buttonGo,
      .buttonCancel {
        flex: 1;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: $border-radius;
        font-size: 1rem;
        cursor: pointer;
        transition: $transition;

        &:hover {
          opacity: 0.8;
        }
      }

      .buttonGo {
        background-color: $primary-color;
        color: white;
      }

      .buttonCancel {
        background-color: #e53e3e;
        color: white;
      }
    }
  }
}

$primary-color: #5a67d8;
$secondary-color: #edf2f7;
$bg-color: #f7fafc;
$font-color: #2d3748;
$transition: 1s ease-in-out;

.banner {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
  transition: background-image $transition;

  box-sizing: border-box;
  padding: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    color: white;
    .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;

      .title {
        font-size: 3vmax;
        font-weight: bold;
        margin: 0;
      }

      .subtitle {
        font-size: 1.2rem;
        margin: 0.5rem 0;
      }
    }
    .stats {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      gap: 1rem;

      .stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0.8rem;
        border-radius: 5px;
        width: 15%;
        height: 4vmax;

        span {
          font-size: 1.2vmax;
          font-weight: bold;
        }

        p {
          font-size: 0.8rem;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .banner {
    height: 500px;
    overflow: hidden;
    // margin: 0 1rem;
    .content {
      margin: 1rem 0;
      flex-direction: column;
      width: 100%;
      .header {
        width: 100%;
        .title {
          font-size: 2rem;
        }

        .subtitle {
          font-size: 1.5rem;
        }
      }

      .stats {
        width: 80%;
        justify-content: space-between;
        flex-direction: row;
        // padding: 1rem;

        .stat {
          width: 15%;
          padding: 1rem;
          span {
            font-weight: bold;
            font-size: 0.8rem;
          }
          p{
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

$primary-color: #5a67d8;
$secondary-color: #edf2f7;
$bg-color: #edf6fc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;

.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $bg-color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  overflow: hidden;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    .logoDiv {
      .logoLink {
        text-decoration: none;
        color: inherit;
      }

      .logo {
        font-size: 1.5rem;
        background-color: $primary-color;
        color: $secondary-color;
        padding: 0.5rem 1rem;
        border-radius: $border-radius;
        cursor: pointer;
        margin-right: 2rem;
        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
    }

    .menu {
      display: flex;

      .menuItem {
        margin-right: 1rem;
        font-size: 1rem;
        cursor: pointer;
        background-color: $bg-color;
        text-decoration: none;
        color: $font-color;
        padding: 0.5rem;
        border-radius: $border-radius;
        &:hover {
          color: $primary-color;
          background-color: lighten($primary-color, 40%);
        }
      }

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        .search {
          display: flex;
          align-items: center;
          background-color: $secondary-color;
          border: 1px solid rgba(159, 159, 159, 0.5);
          border-radius: $border-radius;
          padding: 0.5rem;
          margin-right: 1rem;

          .searchIcon {
            margin-right: 0.5rem;
          }

          .searchInput {
            border: none;
            background: none;
            color: rgba(159, 159, 159, 1);
            font-size: 1rem;
            &:focus {
              outline: none;
            }
          }
        }

        .connectWallet {
          background-color: $primary-color;
          color: $secondary-color;
          padding: 0.5rem 1rem;
          border-radius: $border-radius;
          cursor: pointer;
          margin-right: 1rem;
          &:hover {
            background-color: darken($primary-color, 5%);
          }
        }

        .bagIcon {
          cursor: pointer;
        }
      }
    }
    .hamburger {
      display: none;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}

@media (max-width: 740px) {
  .navbar {
    padding: 0.5rem 0;
    overflow: hidden;

    .container {
      flex-direction: row;
      // align-items: flex-start;
      gap: 1rem;

      .hamburger {
        display: block;
      }

      .logo {
        margin-right: 0;
        margin-bottom: 0;
      }

      .menu {
        display: none;
        flex-direction: column;
        width: 40%;

        .left,
        .right {
          width: 100%;
          flex-direction: column;

          .menuItem {
            margin-right: 0;
            margin-bottom: 0.5rem;
            width: 100%;
            text-align: center;
          }
        }

        .right {
          .search {
            display: none;
          }

          .connectWallet {
            margin-right: 0;
            margin-bottom: 0.5rem;
            width: 100%;
            text-align: center;
          }
        }
      }

      .menu.open {
        display: block;
      }
    }
  }
}

$primary-color: #5a67d8;
$bg-color: #f7fafc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;

.tokenHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: darken($bg-color, 5%);
  border-radius: $border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-height: 300px; 
  margin: 2rem;
  overflow: hidden;

  h2 {
    color: $primary-color;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .historyTable {
    display: block;
    border-collapse: collapse;
    overflow-y: auto; 
    overflow-x: auto;
    margin: 1rem 0;

    th,
    td {
      border: 1px solid #e2e8f0;
      padding: 10px 15px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    th {
      background-color: $primary-color;
      color: white;
      position: sticky;
      top: 0; 
    }

    tr:nth-child(even) {
      background-color: #f7fafc;
    }

    tr:hover {
      background-color: #e2e8f0;
      transition: background-color $transition;
    }

    td {
      max-width: 200px;
    }
  }
}

@media (max-width: 740px) {
  .tokenHistory {
    margin: 1rem;

    h2 {
      font-size: 1.2rem;
    }

    .historyTable {
      display: block; 
      max-width: 90%; 
      overflow-x: auto; 

      th,
      td {
        padding: 1rem;
        white-space: nowrap; 
      }
    }
  }
}

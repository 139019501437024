$primary-color: #5a67d8;
$secondary-color: #edf2f7;
$bg-color: #f7fafc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg-color;
  color: $font-color;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;

  .hero {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 2.5rem;
      color: $primary-color;
    }

    p {
      font-size: 1.2rem;
      color: $font-color;
    }
  }

  .content {
    max-width: 800px;
    width: 100%;

    .section {
      margin-bottom: 2rem;

      h2 {
        font-size: 1.8rem;
        color: $primary-color;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        color: $font-color;
        line-height: 1.6;
        margin-bottom: 1rem;
      }

      ul {
        list-style-type: disc;
        padding-left: 1.5rem;
        li {
          font-size: 1rem;
          color: $font-color;
          margin-bottom: 0.5rem;
        }
      }

      ol {
        list-style-type: decimal;
        padding-left: 1.5rem;
        li {
          font-size: 1rem;
          color: $font-color;
          margin-bottom: 0.5rem;

          strong {
            color: $primary-color;
          }

          .link {
            color: $primary-color;
            text-decoration: underline;
            &:hover {
              color: darken($primary-color, 10%);
            }
          }
        }
      }

      .ctaButton {
        display: inline-block;
        background-color: $primary-color;
        color: $secondary-color;
        padding: 0.75rem 1.5rem;
        border-radius: $border-radius;
        text-decoration: none;
        font-size: 1rem;
        transition: background-color $transition;

        &:hover {
          background-color: darken($primary-color, 5%);
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .about {
    .hero {
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }

    .content {
      .section {
        h2 {
          font-size: 1.5rem;
        }

        p,
        ul,
        ol {
          font-size: 0.9rem;
        }

        .ctaButton {
          font-size: 0.9rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  // background-color: black;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: auto;
  color: white;
  font-family: Arial, sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  // height: 100%;
  flex: 1;

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    // height: 100%;
    flex: 1;
  }
}

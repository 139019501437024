$primary-color: #5a67d8;
$bg-color: #f7fafc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;

body {
  background-color: $bg-color;
  color: $font-color;
  font-family: "Helvetica Neue", Arial, sans-serif;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 2rem;
}

.nftDetail {
  background-color: white;
  padding: 20px 20px 0 20px;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: 20px;
  }

  .nftInfo {
    text-align: center;

    h1 {
      color: $primary-color;
      font-size: 2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: $font-color;
      margin: 5px 0;
    }
  }

  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    gap: 1rem;
    border: none;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-top: 0.5rem;
    cursor: pointer;
    color: white;
    transition: background-color $transition;

    &.disabled {
      background-color: lighten($primary-color, 20%);
      cursor: not-allowed;
    }

    &:hover:not(.disabled) {
      background-color: darken($primary-color, 10%);
    }
  }
  &:hover{
    .options{
      background-color: $primary-color;
    }
  }
}

.tokenHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

$primary-color: #5a67d8;
$secondary-color: #edf2f7;
$bg-color: #f7fafc;
$font-color: #2d3748;
$border-radius: 8px;
$transition: 0.3s ease-in-out;

body {
  background-color: $bg-color;
  color: $font-color;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;

  h2 {
    color: $primary-color;
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
    color: $font-color;
  }
}

.header {
  text-align: center;
  margin-bottom: 20px;

  h2 {
    color: $primary-color;
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
    color: $font-color;
  }

  button {
    height: 2rem;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color $transition;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  gap: 1rem;

  input[type="text"],
  input[type="file"] {
    width: 100%;
    height: 2rem;
    margin: 10px 0;
    border: 1px solid #e2e8f0;
    border-radius: $border-radius;
  }

  img {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 50%;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    height: 3rem;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color $transition;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
    &:disabled {
      background-color: lighten($primary-color, 20%);
      cursor: not-allowed;
    }
  }
}

.nftGallery {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;

  .nftItem {
    background-color: white;
    padding: 10px;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 180px;
    text-align: center;
    transition: transform $transition;

    &:hover {
      transform: translateY(-5px);
    }

    img {
      width: 100%;
      border-radius: $border-radius;
      margin-bottom: 10px;
    }

    .nftName {
      font-size: 1.2rem;
      color: $primary-color;
    }

    .nftDescription {
      font-size: 0.8rem;
      color: darken($font-color, 20%);
    }
  }

  .nftLink {
    text-decoration: none;
    color: inherit;
  }
}


@media (max-width: 740px) {
  .container{
    margin: 2rem;
  }
  
}